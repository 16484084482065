import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nonce: 100,
  selectedFromNetwork: null,
  selectedToNetwork: null,
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    updateNonce: (state, action) => {
      state.nonce = action.payload;
    },
    updateSelectedFromNetwork: (state, action) => {
      state.selectedFromNetwork = action.payload;
    },
    updateSelectedToNetwork: (state, action) => {
      state.selectedToNetwork = action.payload;
    },
  },
});

export const {
  updateNonce,
  updateSelectedFromNetwork,
  updateSelectedToNetwork,
} = mainSlice.actions;

export default mainSlice.reducer;
