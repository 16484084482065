import React from "react";
import bridgeImage from "../../assets/images/bridge.png";

const Header = () => {
  return (
    <header className="shadow-lg bg-[#23384f] sticky top-0 z-10 py-3">
      <div className="w-[95%] md:w-[80%] mx-auto">
        <div className="flex justify-center items-center">
          <a href="" className="block">
            <img className="w-[130px]" src={bridgeImage} alt="Logo" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
