import React from "react";
import Header from "../components/Header";
import WalletConnectWrapper from "../utils/walletconnect.js";
import Bridge from "../components/Bridge";

const Landing = () => {
  return (
    <React.Fragment>
      <Header />
      <WalletConnectWrapper>
        <Bridge />
      </WalletConnectWrapper>
    </React.Fragment>
  );
};

export default Landing;
