import React, { useEffect, useState } from "react";

import { AvailableAsset } from "../../../utils/data";
import { ConnectWalletSVG, PlaceHolderSVG } from "../../../assets/svgs";
import Web3 from "web3";
import { BridgeABIs } from "../../../utils/abi";
import { tokenAddresses } from "../../../utils/addresses";

const ToBlock = ({
  setShowWalletConnect,
  isNetworkSelected,
  handleShowNetwork,
  selectedToNetwork,
  toAmount,
  address,
}) => {
  const [toBalance, setToBalance] = useState();

  // To chain balance
  const getBalance = async (from, wallet) => {
    const web3 = new Web3(selectedToNetwork.rpcUrl);
    let tokenContract = new web3.eth.Contract(
      BridgeABIs.TokenABI,
      tokenAddresses[from]
    );
    const balance = await tokenContract.methods.balanceOf(wallet).call();
    const convertedBalance = web3.utils.fromWei(balance.toString(), "ether");
    setToBalance(parseFloat(convertedBalance)?.toFixed(4));
  };

  // fetch balance on To chain
  useEffect(() => {
    if (selectedToNetwork && address) {
      setToBalance();
      getBalance(selectedToNetwork.name, address);
    }
  }, [selectedToNetwork, address]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedToNetwork && address) {
        getBalance(selectedToNetwork.name, address);
      }
    }, 3 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="from-wrapper pb-4">
      <div className="from-title flex justify-between items-center">
        <h3 className="text-white m-0 text-xl">To</h3>
        {!address ? (
          <button
            onClick={() => setShowWalletConnect(true)}
            disabled={!isNetworkSelected}
            className="w-auto px-3.5 bg-[#092441] h-[40px] disabled:cursor-not-allowed flex items-center justify-center rounded-xl cursor-pointer relative overflow-hidden transition-all duration-500 ease-in-out shadow-md hover:scale-105 hover:shadow-lg before:absolute before:top-0 before:-left-full before:w-full before:h-full before:bg-gradient-to-r before:from-[#46719f] before:to-[#172635] before:transition-all before:duration-500 before:ease-in-out before:z-[-1] before:rounded-xl hover:before:left-0 text-[#fff]"
          >
            <ConnectWalletSVG className="w-5 me-[6px] h-5 MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" />
            Connect Wallet
          </button>
        ) : (
          <w3m-button balance="hide" />
        )}
      </div>

      <div className="from-card mt-3 bg-[#1224378a] p-4 rounded-xl">
        <div className="network-wrapper">
          <div
            onClick={() => handleShowNetwork("to")}
            className="network-type bg-[#122437] flex justify-center items-center cursor-pointer p-2.5 rounded-[10px]"
          >
            <div className="choose-network text-center">
              <div className="flex justify-center">
                {selectedToNetwork ? (
                  selectedToNetwork?.icon
                ) : (
                  <PlaceHolderSVG />
                )}
              </div>
              <div className="network-label mt-1 text-white">
                {selectedToNetwork ? selectedToNetwork?.name : "Select network"}
              </div>
            </div>
          </div>

          <div className="asset-amount">
            <div className="asset bg-[#122437] rounded-[10px] py-2.5 px-3.5 text-[#2196F3]">
              <label>Asset</label>
              {!isNetworkSelected ? (
                <div className="asset-dropdown">-</div>
              ) : (
                <div className="selected-asset" style={{ display: "block" }}>
                  <div className="d-flex">
                    {AvailableAsset[0]?.icon}
                    <span>{AvailableAsset[0]?.name}</span>(
                    {AvailableAsset[0]?.symbol})
                  </div>
                </div>
              )}
            </div>

            <div className="amount-wrapper">
              <div className="amount">
                <label>Amount</label>
                {!isNetworkSelected ? (
                  <div className="amount-dropdown">-</div>
                ) : (
                  <div className="amount-input" style={{ display: "block" }}>
                    <input
                      type="text"
                      disabled={true}
                      className="amount-value"
                      placeholder="0.00"
                      value={toAmount}
                    />
                  </div>
                )}
              </div>

              <div className="balance">
                <label>Balance</label>
                {toBalance && isNetworkSelected ? (
                  <div className="balance-dropdown">{toBalance}</div>
                ) : (
                  <div className="balance-dropdown">-</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ToBlock;
