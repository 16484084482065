import React from "react";
import { CloseSVG, SearchSVG, WalletConnectSVG } from "../../../assets/svgs";

import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";

const ConnectWallet = ({ onShow, onClose }) => {
  const { open } = useWeb3Modal();
  const { address } = useWeb3ModalAccount();

  return (
    <div className={`wallet-wrapper ${onShow && "visible"}`}>
      <button onClick={() => onClose(false)} className="close-modal">
        <CloseSVG />
      </button>
      <div className="inner-wallet">
        <h3 className="text-center mb-1 text-light">Connect Wallet</h3>
        <div className="flex items-center justify-center w-full mt-4">
          {address && <w3m-button />}
        </div>
        <div className="search-network my-3">
          <input
            type="search"
            className="form-control search-nw"
            placeholder="Search"
          />
          <span className="search-icon">
            <SearchSVG />
          </span>
        </div>
        <div className="walletList-container">
          <div
            className="wallet-item d-flex justify-content-between"
            onClick={() => open()}
          >
            <div className="wallet-media d-flex justify-content-between">
              <div>
                <WalletConnectSVG height={32} width={32} />
              </div>
              <div className="text-light">WalletConnect</div>
            </div>

            <div className="wallet-name">ETHEREUM</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
