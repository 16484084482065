import React from "react";
import { CloseSVG, SearchSVG } from "../../../assets/svgs";
import { AvailableNetworks } from "../../../utils/data";

const SelectNetwork = ({ onShow, onClose, onSelect }) => {
  return (
    <div className={`sending-wrapper ${onShow && "visible"}`}>
      <button className="close-modal" onClick={() => onClose(false)}>
        <CloseSVG />
      </button>

      <div className="inner-content">
        <h3 className="text-center mb-1 text-light">Sending from</h3>
        <p className="text-center m-0 text-light">Select Network</p>

        <div className="search-network my-3">
          <input
            type="search"
            className="form-control search-nw"
            placeholder="Search networks"
          />
          <span className="search-icon">
            <SearchSVG />
          </span>
        </div>

        <div className="all-networks">
          {AvailableNetworks?.map((network) => (
            <div className="c-network" onClick={() => onSelect(network)}>
              <div className="network-icon">{network?.icon}</div>
              <div className="network-name">{network?.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectNetwork;
