import { toast } from "react-toastify";
import Web3 from "web3";
import { BridgeABIs } from "./abi";
import { tokenAddresses } from "./addresses";

export const getBalance = async (setBalance, from, wallet) => {
  console.log(from);
  console.log(wallet);
  const web3 = new Web3(window.ethereum);
  let tokenContract = new web3.eth.Contract(
    BridgeABIs.TokenABI,
    tokenAddresses[from]
  );
  const balance = await tokenContract.methods.balanceOf(wallet).call();
  console.log(balance);
  const convertedBalance = web3.utils.fromWei(balance.toString(), "ether");
  setBalance(parseFloat(convertedBalance)?.toFixed(4));
};

export default function wrapAddress(address) {
  const wrappedAddress = `${address?.slice(0, 6)}...${address?.slice(-4)}`;
  return wrappedAddress;
}

export const showToast = (type, message, id) => {
  const customID = id;
  switch (type) {
    case "error": {
      toast.error(message, {
        position: "bottom-left",
        autoClose: 3000,
        theme: "colored",
        toastId: customID,
        pauseOnHover: true,
      });
      break;
    }
    case "success": {
      toast.success(message, {
        position: "bottom-left",
        autoClose: 3000,
        theme: "colored",
        toastId: customID,
        pauseOnHover: true,
      });
      break;
    }
    default: {
      console.log("something went wrong with toast");
    }
  }
};
