import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "../pages";

const Routing = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<Landing />} />
      </Routes>
    </Suspense>
  );
};

export default Routing;
