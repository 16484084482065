import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import {
  mainnetChainIds,
  mainnetRPCUrls,
  testnetChainIds,
  testnetRPCUrls,
} from "./data";

// 1. Get projectId
const projectId = process.env.REACT_APP_WALLETCONNECT_ID;

//bsc testnet
const bscTestnet = {
  chainId: testnetChainIds.BSC,
  name: "BNB Smart Chain Testnet",
  rpcUrl: testnetRPCUrls.BSC,
  currency: "tBNB",
  explorerUrl: "https://testnet.bscscan.com/",
};

// bsc mainnet
const BscMainnet = {
  name: "BNB Smart Chain Mainnet",
  chainId: mainnetChainIds.BSC,
  currency: "BNB",
  rpcUrl: mainnetRPCUrls.BSC,
  explorerUrl: "https://bscscan.com/",
};

//base testnet
const baseTestnet = {
  chainId: testnetChainIds.BASE,
  name: "Base Spolia",
  currency: "ETH",
  rpcUrl: testnetRPCUrls.BASE,
  explorerUrl: "https://base.blockscout.com/",
};

// base mainnet
const baseMainnet = {
  chainId: mainnetChainIds.BASE,
  name: "base",
  currency: "ETH",
  rpcUrl: mainnetRPCUrls.BASE,
  explorerUrl: "https://basescan.org/",
};

const testnetChains = [baseTestnet, bscTestnet];
const mainnetChains = [baseMainnet, BscMainnet];

const testnetTokens = {
  97: {
    address: "0x841434324A98FdC2B9daf926f2F73c2ED76151b3",
  },
  84532: {
    address: "0x973B38229b6cA6405d4b9cA83D1B36D2e7f0646a",
  },
};

const mainnetTokens = {
  56: {
    address: "0x45a437309eA471a636e552440F9E605D31AF37F1",
  },
  8453: {
    address: "0x973B38229b6cA6405d4b9cA83D1B36D2e7f0646a",
  },
};

// 3. Create a metadata object
const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "https://mywebsite.com", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});
// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: mainnetChains,
  projectId,
  tokens: mainnetTokens,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true, // Optional - false as default
});

export default function WalletConnectWrapper({ children }) {
  return children;
}
