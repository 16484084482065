import React, { useEffect, useState } from "react";

import { ConnectWalletSVG, PlaceHolderSVG, SwitchSVG } from "../../assets/svgs";
import SelectNetwork from "./subcomponents/SelectNetwork";
import ConnectWallet from "./subcomponents/ConnectWallet";
import {
  useWeb3ModalAccount,
  useSwitchNetwork,
} from "@web3modal/ethers5/react";
import { AvailableAsset, AvailableNetworks } from "../../utils/data";
import BridgeButton from "../BridgeButton";
import ToBlock from "./subcomponents/ToBlock";
import { getBalance, showToast } from "../../utils/functions";
import { useDispatch } from "react-redux";
import {
  updateSelectedFromNetwork,
  updateSelectedToNetwork,
} from "../../redux/slices/main";

export default function Bridge() {
  const dispatch = useDispatch();

  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { switchNetwork } = useSwitchNetwork();

  const [event, setEvent] = useState("");
  const [isNetworkSelected, setIsNetworkSelected] = useState(false);

  const [showWalletConnect, setShowWalletConnect] = useState(false);
  const [showNetworkSelect, setShowNetworkSelect] = useState(false);

  const handleShowNetwork = (type) => {
    setEvent(type);
    setShowNetworkSelect(true);
  };

  const [selectedFromNetwork, setSelectedFromNetwork] = useState(
    // useSelector((state) => state.main.selectedFromNetwork)
    null
  );
  const [selectedToNetwork, setSelectedToNetwork] = useState(
    // useSelector((state) => state.main.selectedToNetwork)
    null
  );

  const [balance, setBalance] = useState();

  const [fromAmount, setFromAmount] = useState();
  const [toAmount, setToAmount] = useState();

  // switch wallet network when selecting network
  useEffect(() => {
    console.log(chainId);
    console.log(selectedFromNetwork);
    if (chainId !== selectedFromNetwork?.chainId) {
      console.log("switching");
      switchNetwork(selectedFromNetwork?.chainId);
    }
  }, [chainId, selectedFromNetwork, isConnected]);

  // get balance on change chain id
  useEffect(() => {
    console.log(chainId, address, selectedFromNetwork);
    if (chainId && address && selectedFromNetwork) {
      console.log("getting balance");
      setBalance();
      getBalance(setBalance, selectedFromNetwork.name, address);
    }
  }, [chainId, address, selectedFromNetwork]);

  // fetch balance every 3 minutes if wallet connected
  useEffect(() => {
    const interval = setInterval(() => {
      if (isConnected && selectedFromNetwork) {
        setBalance();
        getBalance(setBalance, selectedFromNetwork.name, address);
      }
    }, 3 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  // handle select network
  const onSelectNetwork = (selected) => {
    AvailableNetworks.map((network) => {
      if (network.name === selected.name) {
        const toNetwork = AvailableNetworks.filter(
          (data, _) => data.name !== network.name
        )[0];
        if (event === "from") {
          setSelectedFromNetwork(network);
          setSelectedToNetwork(toNetwork);
          dispatch(updateSelectedFromNetwork(network));
          dispatch(updateSelectedToNetwork(toNetwork));
        } else {
          setSelectedToNetwork(network);
          setSelectedFromNetwork(toNetwork);
          dispatch(updateSelectedFromNetwork(toNetwork));
          dispatch(updateSelectedToNetwork(network));
        }
      }
    });

    setIsNetworkSelected(true);
    setShowNetworkSelect(false);
  };

  // handle switch network
  const handleSwitchNetworks = () => {
    const from = selectedFromNetwork;
    const to = selectedToNetwork;
    setSelectedFromNetwork(to);
    setSelectedToNetwork(from);
    dispatch(updateSelectedFromNetwork(to));
    dispatch(updateSelectedToNetwork(from));
    setToAmount();
    setFromAmount();
  };

  return (
    <div className="home-container">
      <div className="home-content">
        <section className="from-wrapper pt-4">
          <div className="from-title flex justify-between items-center">
            <h3 className="text-white m-0 text-xl">From</h3>
            {!address ? (
              <button
                onClick={() => setShowWalletConnect(true)}
                disabled={!isNetworkSelected}
                className="w-auto px-3.5 bg-[#092441] h-[40px] disabled:cursor-not-allowed flex items-center justify-center rounded-xl cursor-pointer relative overflow-hidden transition-all duration-500 ease-in-out shadow-md hover:scale-105 hover:shadow-lg before:absolute before:top-0 before:-left-full before:w-full before:h-full before:bg-gradient-to-r before:from-[#46719f] before:to-[#172635] before:transition-all before:duration-500 before:ease-in-out before:z-[-1] before:rounded-xl hover:before:left-0 text-[#fff]"
              >
                <ConnectWalletSVG className="w-5 me-[6px] h-5 MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" />
                Connect Wallet
              </button>
            ) : (
              <w3m-button balance="hide" />
            )}
          </div>

          <div className="from-card mt-3 bg-[#1224378a] p-4 rounded-xl">
            <div className="network-wrapper">
              <div
                onClick={() => handleShowNetwork("from")}
                className="network-type bg-[#122437] flex justify-center items-center cursor-pointer p-2.5 rounded-[10px]"
              >
                <div className="choose-network text-center">
                  <div className="flex justify-center">
                    {selectedFromNetwork ? (
                      selectedFromNetwork?.icon
                    ) : (
                      <PlaceHolderSVG />
                    )}
                  </div>
                  <div className="network-label mt-1 text-white">
                    {selectedFromNetwork
                      ? selectedFromNetwork?.name
                      : "Select network"}
                  </div>
                </div>
              </div>

              <div className="asset-amount">
                <div className="asset bg-[#122437] rounded-[10px] py-2.5 px-3.5 text-[#2196F3]">
                  <label>Asset</label>
                  {!isNetworkSelected ? (
                    <div className="asset-dropdown">-</div>
                  ) : (
                    <div
                      className="selected-asset"
                      style={{ display: "block" }}
                    >
                      <div className="d-flex">
                        {AvailableAsset[0]?.icon}
                        <span>{AvailableAsset[0]?.name}</span>(
                        {AvailableAsset[0]?.symbol})
                      </div>
                    </div>
                  )}
                </div>

                <div className="amount-wrapper">
                  <div className="amount">
                    <label>Amount</label>
                    {!isNetworkSelected ? (
                      <div className="amount-dropdown">-</div>
                    ) : (
                      <div
                        className="amount-input"
                        style={{ display: "block" }}
                      >
                        <input
                          type="text"
                          className="amount-value"
                          placeholder="0.00"
                          disabled={!balance}
                          value={fromAmount}
                          onChange={(e) => {
                            const filteredValue = e.target.value
                              .replace(/[^\d.]/g, "")
                              .replace(/(\..*)\./g, "$1");

                            if (filteredValue < balance) {
                              setFromAmount(filteredValue);
                              setToAmount(filteredValue);
                            } else {
                              showToast(
                                "error",
                                "Amount cannot be higher then balance.",
                                "amountERR"
                              );
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="balance">
                    <label>Balance</label>
                    {balance && isNetworkSelected ? (
                      <div className="balance-dropdown">{balance}</div>
                    ) : (
                      <div className="balance-dropdown">-</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="replace-network my-3 d-flex justify-content-center">
          <button
            onClick={() => handleSwitchNetworks()}
            className="d-flex justify-content-center align-items-center"
          >
            <SwitchSVG />
          </button>
        </div>

        <ToBlock
          setShowWalletConnect={setShowWalletConnect}
          isNetworkSelected={isNetworkSelected}
          handleShowNetwork={handleShowNetwork}
          selectedToNetwork={selectedToNetwork}
          toAmount={toAmount}
          setToAmount={setToAmount}
          address={address}
          setFromAmount={setFromAmount}
        />

        {toAmount && fromAmount && (
          <div className="replace-network my-3 d-flex justify-content-center">
            <BridgeButton
              setBalance={setBalance}
              data={{
                fromAmount,
                toAmount,
                selectedFromNetwork,
                selectedToNetwork,
                balance,
                address,
              }}
            />
          </div>
        )}

        <SelectNetwork
          onShow={showNetworkSelect}
          onClose={setShowNetworkSelect}
          onSelect={onSelectNetwork}
        />

        {/* Connect Wallet Modal Starts Here */}
        <ConnectWallet
          onShow={showWalletConnect}
          onClose={setShowWalletConnect}
        />
        {/* Connect Wallet Modal Ends Here */}
      </div>
    </div>
  );
}
