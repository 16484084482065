import { BscSVG, BaseSVG, PQuestSVG } from "../assets/svgs";

export const mainnetChainIds = { BASE: 8453, BSC: 56 };
export const testnetChainIds = { BASE: 84532, BSC: 97 };

export const mainnetRPCUrls = {
  BASE: "https://mainnet.base.org/",
  BSC: "https://bsc-dataseed1.binance.org/",
};
export const testnetRPCUrls = {
  BASE: "https://sepolia.base.org/",
  BSC: "https://data-seed-prebsc-1-s3.binance.org:8545/",
};

export const AvailableAsset = [
  {
    name: "Pepe Quest",
    symbol: "$PEPEQ",
    icon: <PQuestSVG height={24} width={24} />,
  },
];

export const AvailableNetworks = [
  {
    name: "BASE",
    chainId: mainnetChainIds.BASE,
    icon: <BaseSVG />,
    rpcUrl: mainnetRPCUrls.BASE,
  },
  {
    name: "BSC",
    chainId: mainnetChainIds.BSC,
    icon: <BscSVG />,
    rpcUrl: mainnetRPCUrls.BSC,
  },
];
