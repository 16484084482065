import React, { useState } from "react";
import baseURL from "../../baseURL";
import axios from "axios";
import Web3 from "web3";
import { BridgeABIs } from "../../utils/abi";
import { contractAddresses, tokenAddresses } from "../../utils/addresses";
import { useDispatch, useSelector } from "react-redux";
import { updateNonce } from "../../redux/slices/main";
import { getBalance, showToast } from "../../utils/functions";
import { Spinner } from "react-bootstrap";
// import { testTokenAbi } from "../utils/tokenAbi";

const BridgeButton = ({ data, setBalance }) => {
  const [isBridging, setIsBridging] = useState(false);

  // nonce state
  const dispatch = useDispatch();
  const nonce = useSelector((state) => state.main.nonce);

  // get signature
  async function signTransaction(web3, from, to, amount, nonce) {
    const message = web3.utils.soliditySha3(
      { t: "address", v: from },
      { t: "address", v: to },
      { t: "uint256", v: amount },
      { t: "uint256", v: nonce }
    );

    const signature = await window?.ethereum
      .request({
        method: "personal_sign",
        params: [message, from],
      })
      .catch((error) => {
        console.log("Signature Error", error);
        setIsBridging(false);
        showToast("error", error.message, "errTrxSign");
      });

    return signature;
  }

  // Using setTimeout
  setTimeout(() => {
    // Code to execute after 3 seconds
  }, 3000); // 3000 milliseconds = 3 seconds

  // Using async function with Promise
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // bridge init
  const handleBridge = async () => {
    // start
    setIsBridging(true);
    const web3 = new Web3(window?.ethereum);

    // constants
    // chain & address
    const from = data?.selectedFromNetwork.name;
    const to = data?.selectedToNetwork?.name;
    const walletAddress = data?.address;

    // value data
    const amount = web3.utils.toWei(data?.fromAmount, "ether");
    const gasPrice = await web3.eth.getGasPrice();

    // contract addresses
    const tokenAddress = tokenAddresses[from];
    const fromAddress = contractAddresses[from];
    const fromAbi = BridgeABIs[from];
    const toAddress = contractAddresses[to];

    console.log("from", from);
    console.log("to", to);
    console.log("walletAddress", walletAddress);
    console.log("amount", amount);
    console.log("gasPrice", gasPrice);
    console.log("tokenAddress", tokenAddress);
    console.log("fromAddress", fromAddress);
    console.log("toAddress", toAddress);

    // Approve token -> from as spender

    //below code is commented because of test token uncomment it with correct token address later

    // let tokenContract = new web3.eth.Contract(
    //   BridgeABIs.TokenABI,
    //   tokenAddress
    // );

    const Countres = await web3.eth.getTransactionCount(walletAddress);
    const nounce = parseInt(Countres);
    console.log(nounce);
    let tokenContract = new web3.eth.Contract(
      BridgeABIs.TokenABI,
      tokenAddress //testing token address owner is 0xB1C96c48F5Fc7713306102D6a83B2f9219F32cb2
    );

    const res = await tokenContract.methods
      .approve(fromAddress, amount)
      .send({
        from: walletAddress,
        gasPrice: gasPrice,
        gas: 1000000,
      })
      .catch((err) => {
        console.log("Token Approve Error", err);
        setIsBridging(false);
        showToast("error", "Token Approval Failed", "errTokenApp");
      });
    console.log("Token Approve: ", res);
    if (res === undefined) {
      setIsBridging(false);
      return;
    }

    await delay(3000); // Wait for 3 seconds

    // Contract
    let BridgeContract = new web3.eth.Contract(fromAbi, fromAddress);

    // Sign
    const signature = await signTransaction(
      web3,
      walletAddress,
      walletAddress,
      amount,
      nounce
    );
    console.log("signature", signature);
    if (signature === undefined) {
      setIsBridging(false);
      return;
    }

    // call method (deposit for base to bsc, withdraw for bsc to base)
    let methodName;
    if (from === "BSC") {
      methodName = "withdraw";
    } else {
      methodName = "deposit";
    }

    const contractData = {
      from: walletAddress,
      to: walletAddress,
      amount,
      nounce,
      signature,
    };

    const cTx = BridgeContract.methods[methodName](
      contractData.to,
      contractData.amount,
      contractData.nounce,
      contractData.signature
    );

    const gasEstimate = await cTx.estimateGas({
      from: walletAddress,
    });

    console.log("gasEstimate", gasEstimate);
    console.log("contractData", contractData);
    console.log("methodName", methodName);
    //dont sent object in blockchian it will give error so send data one by one
    //if in other function if you have sent object like ...contractData then it will give error so change it like below
    const bridgeRes = await BridgeContract.methods[methodName](
      // contractData.from,
      contractData.to,
      contractData.amount,
      contractData.nounce,
      contractData.signature
    )
      .send({
        from: walletAddress,
        gasPrice: gasPrice,
        gas: gasEstimate,
      })
      .catch((err) => {
        console.log("Contract Method Failed", err);
        setIsBridging(false);
        showToast("error", "Contract Method Call Failed", "errContCall");
      });
    console.log("Contract Call: ", bridgeRes);
    if (bridgeRes === undefined) {
      setIsBridging(false);
      return;
    }

    //API CALL

    const apiData = {
      action: methodName,
      chain: from,
      from: walletAddress,
      to: walletAddress,
      amount: amount,
      newNonce: nounce,
      signature: signature,
    };

    console.log("apiData", apiData);
    axios
      .post(`${baseURL}transfer-token`, apiData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("API RESPONSE: ", response);
        getBalance(setBalance, walletAddress);
        dispatch(updateNonce(nonce + 1));
        setIsBridging(false);
        showToast("success", "Bridge Successfull", "succBridge");
      })
      .catch((err) => {
        console.log("API Error", err);
        setIsBridging(false);
      });
    //end
  };
  return (
    <button
      disabled={isBridging}
      onClick={() => handleBridge()}
      className="w-auto px-3.5 bg-[#092441] h-[40px] disabled:cursor-not-allowed flex items-center justify-center rounded-xl cursor-pointer relative overflow-hidden transition-all duration-500 ease-in-out shadow-md hover:scale-105 hover:shadow-lg before:absolute before:top-0 before:-left-full before:w-full before:h-full before:bg-gradient-to-r before:from-[#46719f] before:to-[#172635] before:transition-all before:duration-500 before:ease-in-out before:z-[-1] before:rounded-xl hover:before:left-0 text-[#fff]"
    >
      {isBridging ? (
        <Spinner animation="border" size="sm" role="status"></Spinner>
      ) : (
        "Bridge"
      )}
    </button>
  );
};

export default BridgeButton;
